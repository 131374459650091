import { useState } from "react";
import { useHistory } from "react-router-dom";
import { isLoggedIn, login } from '../../services/authentification';
import './Login.scss';


const Login = () => {
    const history = useHistory();
    const [code, setCode] = useState('');
    const [error, setError] = useState(false);

    if (isLoggedIn()) {
        history.push('/dashboard');
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const response = login(code);

        if (response) {
            setError(false);
            history.push('/dashboard');
        }

        setError(true);
    }

    return (
        <div className="login container d-grid gap-4 mt-4">
            <h3 className="login__title">
                Identifiez-vous
            </h3>
            <form className="login__form" onSubmit={handleSubmit}>
                <div className="mb-2">
                    <label htmlFor="identification" className="form-label">
                        Saisissez votre code:
                    </label>
                    <input
                        id="identification"
                        name="identification"
                        type="number"
                        className="form-control"
                        placeholder="Code d'identification"
                        aria-label="Code d'identification"
                        value={code}
                        onChange={event => setCode(event.target.value)}
                    />
                    {error && <div className="login__error">Mauvais code d'identification.</div>}
                </div>
                <button className="btn btn-primary w-100" type="submit" aria-label="Authentification">
                    Authentification
                </button>
            </form>
        </div>
    )
}

export default Login;
