import './Header.scss';
import logo from '../../assets/logo.png';
import { useLocation, useHistory, NavLink, matchPath } from 'react-router-dom';
import { isLoggedIn } from '../../services/authentification';
import { downloadLabels } from "../../clients/toolbox";
import LoadingButton from '../LoadingButton/LoadingButton';

const Header = ({ withBackground }) => {
    const history = useHistory();
    const location = useLocation();

    const match = matchPath(location.pathname, '/products/:code');
    const isProductPage = !!match;
    const code = isLoggedIn() && isProductPage ? match.params.code : null;

    let goBack = () => history.goBack();
    if (location.pathname === '/stats') {
        goBack = () => history.push('/dashboard');
    }

    return (
        <header className={`navbar ${withBackground ? "with-background" : ""}`}>
            <div className="col left">
                <button onClick={goBack} className={location.pathname === '/' ? 'd-none' : ''} aria-label="Page précédente">
                    <i className="bi bi-chevron-left"></i>
                </button>
            </div>
            <div className="col-6">
                {!isProductPage &&
                    <NavLink to="/">
                        <img alt="Alltricks logo" src={logo}></img>
                    </NavLink>
                }
                {(isLoggedIn() && isProductPage && code) &&
                    <LoadingButton onClick={() => downloadLabels([code])} color="outline-light">
                        &nbsp;Balisage
                    </LoadingButton>
                }
            </div>
            <div className="col right">
                {isLoggedIn() &&
                    <NavLink to="/dashboard" aria-label="Panneau d'administration">
                        <i className="bi bi-person-circle"></i>
                    </NavLink>
                }
                {!isLoggedIn() &&
                    <NavLink to="/login" aria-label="Se connecter">
                        <i className="bi bi-people"></i>
                    </NavLink>
                }
            </div>
        </header>
    )
}

export default Header;
