import './Statistics.scss'

const Statistics = () => (
    <iframe
        title="DataStudio"
        src={process.env.REACT_APP_DATASTUDIO_URL}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
    ></iframe>
);

export default Statistics;
