import download from 'downloadjs'
import { set as mementoSet, has as mementoHas, get as mementoGet } from '../services/memento'

export const API_URL = process.env.REACT_APP_TOOLBOX_API_URL;
export const API_KEY = process.env.REACT_APP_TOOLBOX_API_KEY;

export const FEDID_URL = process.env.REACT_APP_FEDID_URL;
export const FEDID_SECRET = process.env.REACT_APP_FEDID_SECRET;

let token = null

export const buildHeaders = async () => {
    const headers = new Headers();
    headers.append('content-type', 'application/json;charset=utf-8');
    headers.append('x-api-key', API_KEY);

    if (null === token) {
        const response = await fetch(FEDID_URL, {
            method: 'POST',
            headers: {
              'Authorization': 'Basic ' + FEDID_SECRET,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({ 'grant_type': 'client_credentials'})
        })

        const body = await response.json();
        token = body['access_token'];
    }

    if (token) {
        headers.append('Authorization', `Bearer ${token}`)
    }

    return headers;
}

export const fetchProductInformation = async (code) => {
    const headers = await buildHeaders();
    const requestOptions = {
        method: 'GET',
        headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${API_URL}/products/${code}`, requestOptions);

        if (response.status === 200) {
            const content = await response.json();
            mementoSet(code, { status: response.status, _data: content, data: content });
            return content;
        }

        mementoSet(code, { status: response.status, _data: null, data: null });
        return null;
    } catch ($exception) {
        mementoSet(code, { status: 500, _data: null, data: null });
        throw new Error('Une erreur serveur est survenue.');
    }
}

export const getProductInformation = async (code) => {
    // use memento cache
    if (mementoHas(code) && mementoGet(code).data !== null) {
        return mementoGet(code).data
    }

    return await fetchProductInformation(code);
}

export const validURL = (str) => {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const getProductsLabel = async (products) => {
    const headers = await buildHeaders();

    products = products.filter(v => v);

    const urlPrefix = localStorage.getItem('app.labelling.url_prefix') || '';

    if (urlPrefix !== '') {
        products = products.map(product => {
            const urlWithPrefix = encodeURI(urlPrefix + product.url);

            if (validURL(urlWithPrefix)) {
                product.url = urlWithPrefix;
            }

            return product
        })
    }

    const requestOptions = {
        method: 'POST',
        headers,
        redirect: 'follow',
        body: JSON.stringify({
            products: [
                ...products,
            ]
        })
    }

    const response = await fetch(`${API_URL}/printable.pdf`, requestOptions);

    if (response.status !== 200) {
        throw new Error('generation failed');
    }

    const blob = await response.blob();
    download(blob, 'balisage.pdf', 'application/pdf')
}


export const downloadLabels = async (codes) => {
    try {
        const products = (await Promise.all(codes.map(async code => await getProductInformation(code)))).filter(p => p);

        if (products.length === 0) {
            return false;
        }

        await getProductsLabel(products);

        return true;
    } catch ($exception) {
        return false;
    }
}
