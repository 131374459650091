import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class LoadingButton extends Component {
    state = {
        loading: false
    }

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        const { onClick } = this.props;

        this.setState({ loading: true });
        onClick(event)
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        const { loading } = this.state;
        const { disabled, children, color = 'primary', icon = 'download' } = this.props;

        return (
            <button className={`btn btn-${color} ${disabled ? "disabled" : ""}`} onClick={this.handleClick} aria-label="Lancer le chargement">
                {!loading && <i className={`bi bi-${icon}`}></i>}
                {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                {children && <Fragment>&nbsp;</Fragment>}
                {children}
            </button>
        )
    }
}

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
};

export default LoadingButton;
