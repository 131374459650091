import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from "./components/Header/Header";
import Homepage from "./views/Homepage/Homepage";
import ProductView from "./views/ProductView/ProductView";
import Statistics from "./views/Statistics/Statistics";
import Scanner from "./views/Scanner/Scanner";
import Labelling from "./views/Labelling/Labelling";
import Product from "./views/Product/Product";
import ProductEdit from "./views/Product/ProductEdit";
import Dashboard from "./views/Dashboard/Dashboard";
import Login from "./views/Login/Login";
import { isLoggedIn } from "./services/authentification";
import { Toaster } from "react-hot-toast";
import Cart from "./views/Cart/Cart";
import CartIdentification from "./views/CartIdentification/CartIdentification";
import CartTender from "./views/CartTender/CartTender";
import ProductCreate from "./views/Product/ProductCreate";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest}>
          {isLoggedIn()
            ? <Component />
            : <Redirect to="/login" />
          }
        </Route>
    );
};

export default function App() {
  return (
    <Router>
      <Header withBackground />
      <div className="view">
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard}></PrivateRoute>
          <PrivateRoute path="/labelling" component={Labelling}></PrivateRoute>
          <PrivateRoute path="/stats" component={Statistics}></PrivateRoute>
          <PrivateRoute path="/cart/payment" component={CartTender}></PrivateRoute>
          <PrivateRoute path="/cart/identification" component={CartIdentification}></PrivateRoute>
          <PrivateRoute path="/cart" component={Cart}></PrivateRoute>
          <PrivateRoute path="/details/:code" component={ProductView}></PrivateRoute>
          <PrivateRoute path="/create-product" component={ProductCreate}></PrivateRoute>
          <PrivateRoute path="/products/:code/edit" component={ProductEdit}></PrivateRoute>
          <Route path="/products/:code" component={Product}></Route>
          <Route path="/scanner" component={Scanner}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/" component={Homepage}></Route>
        </Switch>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
    </Router>
  );
}
