const ProductFormInput = ({ name, schema, value = '', onChange, children }) => {
    const label = schema.title || name;
    return (
        <div className="mb-3">
            <label htmlFor={name} className="form-label">
                {label}
            </label>
            {schema.type === 'array' &&
                <div>
                    <div className="mb-2">{children}</div>
                    {value && value.map((attr, i) => (
                        <div className="input-group mb-2" key={`attribute-${i}`}>
                            <input
                                id={`${name}[${i}].name`}
                                type={schema.of}
                                className="form-control"
                                value={attr.name}
                                onChange={onChange}
                            />
                            <input
                                id={`${name}[${i}].value`}
                                type={schema.of}
                                className="form-control"
                                value={attr.value}
                                onChange={onChange}
                            />
                        </div>
                    ))}
                    <div className="alert alert-secondary fst-italic" role="alert">
                        Seulement les attributs "Poids constructeur (en g)" et "Drop" seront visible sur l'impression chaussures.
                    </div>
                </div>
            }
            {schema.type === 'enum' &&
                <select id={name} className="form-select" defaultValue={value} onChange={onChange}>
                    {schema.options.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            }
            {(schema.type !== 'enum' && schema.type !== 'array') &&
                <input
                    id={name}
                    type={schema.type}
                    min={schema.min ?? null}
                    value={value ?? ''}
                    onChange={onChange}
                    className="form-control"
                />
            }
        </div>
    )
}

export default ProductFormInput;
