const _isValid = (code) => {
    return code === process.env.REACT_APP_AUTH_CODE;
}

export const isLoggedIn = () => {
    return localStorage.getItem('app.login') ?? false;
}

export const login = (code) => {
    if (_isValid(code)) {
        localStorage.setItem('app.login', true);
        return true;
    }
    return false;
}

export const logout = () => {
    if (isLoggedIn()) {
        localStorage.removeItem('app.login');
    }
}
