import './CartSummary.scss'

const CartSummary = ({ count, price }) => {
    return (
        <div className="cart-summary">
            <div className="row">
                <div className="col-9 text-start d-flex flex-column align-self-center">
                    <div className="fw-bold">Total: {count} article(s)</div>
                    <div>Total TTC: {price} €</div>
                </div>
                <div className="col-3 text-center">
                    <button className="cart-summary__cta">
                        <i className="bi bi-pencil-square" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CartSummary;
