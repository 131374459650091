import './CartTender.scss';
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import CartTenderItem from "../../components/CartTenderItem/CartTenderItem";

const CartTender = () => {
    return (
        <div className="cart-tender">
          <div className="container">
            <div className="cart-tender__loader">
              <LoadingBar step="3" max="4" />
            </div>
            <div className="cart-tender__view">
              <h1>Paiement</h1>
              <p>Choisissez un moyen de payment</p>
              <div className="d-grid gap-2">
                <CartTenderItem icon="cash" name="AUTRES" />
                <CartTenderItem icon="shop" name="TPE ACCUEIL" connected='on' />
                <CartTenderItem icon="shop" name="TPE ATELIER" connected='on' />
                <CartTenderItem icon="shop" name="TPE RUN" connected='on' />
                <CartTenderItem icon="shop" name="TPE MOBILE 1" connected='off' />

                <button className="btn btn-dark btn-rounded w-100">
                  <i className="bi bi-cart-x" />&nbsp;
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
    )
}

export default CartTender;
