import './LoadingBar.scss'

const LoadingBar = ({ step = 1, max = 4 }) => {
    const gauge = step/max * 100;
    return (
        <div className="loading-bar d-flex justify-content-center align-items-center">
            <div className="loading-bar__base">
                <div className={`loading-bar__gauge w-${gauge}`} />
            </div>
        </div>
    )
}

export default LoadingBar;
