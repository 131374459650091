import { Component } from "react";
import * as ScanditSDK from "scandit-sdk";
import isUrl from '../../helpers/isUrl';
import './Scanner.scss'

class Scanner extends Component {
    barcodePicker = null
    state = {
        code: '',
    }

    componentDidMount() {
        ScanditSDK.configure(process.env.REACT_APP_SCANDIT_LICENCE, {
            engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
        })
        .then(() => {
            return ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
                playSoundOnScan: true,
                guiStyle: 'none',
                videoFit: 'cover', // 'contain'
                scanSettings: new ScanditSDK.ScanSettings({
                    enabledSymbologies: ["ean8", "ean13", "qr"],
                    codeDuplicateFilter: -1,
                }),
            });
        })
        .then((barcodePicker) => {
            this.barcodePicker = barcodePicker;
            this.barcodePicker.on("scan", (scanResult) => {
                const code = scanResult.barcodes[0].data;
                this.setState({ code });
                window.location = isUrl(code) ? code : `/products/${code}`;
            });
        });
    }

    componentWillUnmount() {
        if (!this.barcodePicker) {
            return;
        }
        this.barcodePicker.destroy();
        this.barcodePicker = null;
    }

    render() {
        return (
            <div className="scanner">
                <div id="scandit-barcode-picker"></div>
            </div>
        )
    }
}

export default Scanner;
