import { Component, Fragment } from "react";
import { downloadLabels, fetchProductInformation } from "../../clients/toolbox";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { NavLink } from "react-router-dom";
import { has as mementoHas, get as mementoGet } from '../../services/memento';
import toast from 'react-hot-toast';

const _trClassname = (code) => {
    if (!mementoHas(code)) {
        return ''
    }

    const mState = mementoGet(code);

    if (mState.status === 200) {
        return 'table-success'
    }

    if (mState.status === 500) {
        return 'table-warning'
    }

    return 'table-danger';
}

const _isValid = (code) => {
    const mState = mementoGet(code);
    return mState === null || (mState.status === 200 || mState.status === 500);
}

const NavLinkDisablable = ({ to, disabled, className, ariaLabel, children }) => {
    return (
        <Fragment>
            {!disabled &&
                <NavLink to={to} className={className} aria-label={ariaLabel}>
                    {children}
                </NavLink>
            }
            {disabled &&
                <button disabled className={className} aria-label={ariaLabel}>
                    {children}
                </button>
            }
        </Fragment>
    )
}
class Labelling extends Component {
    state = {
        userInput: '',
        urlPrefix: null,
        optAdv: false,
    }

    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        const userInput = localStorage.getItem('app.labelling.code_collection');

        if (userInput) {
            this.setState({ userInput });
        }

        const urlPrefix = localStorage.getItem('app.labelling.url_prefix');

        if (urlPrefix) {
            this.setState({ urlPrefix });
        }
    }

    async download(codes) {
        const _tId = toast.loading('Génération du balisage...');

        const okStatus = await downloadLabels(codes);

        toast.dismiss(_tId);
        if (okStatus) {
            toast.success('Génération réussie!');
        } else {
            toast.error('Impossibilité de générer le balisage.');
        }

        this.forceUpdate();
    }

    async fetch(code) {
        const _tId = toast.loading('Récupération des informations...');

        const product = await fetchProductInformation(code);

        toast.dismiss(_tId);
        if (product) {
            toast.success('Récupération réussie!');
        } else {
            toast.error('Impossibilité de trouver le produit.');
        }

        this.forceUpdate();
    }

    async fetchAll(codeCollection) {
        const _tId = toast.loading('Récupération des informations...');

        const products = (await Promise.all(codeCollection.map(async code => await fetchProductInformation(code)))).filter(p => p);

        toast.dismiss(_tId);
        if (products.length > 0) {
            toast.success('Récupération réussie!');
        } else {
            toast.error('Impossibilité de trouver le produit.');
        }

        this.forceUpdate();
    }

    render() {
        const { userInput, urlPrefix, optAdv } = this.state;
        const codeCollection = userInput.replaceAll('\n', '/').replaceAll(' ', '/').replaceAll(',', '/').split('/');
        const codeCollectionSliced = codeCollection.slice(0, 20);

        if (userInput !== '') {
            localStorage.setItem('app.labelling.code_collection', userInput);
        }

        if (null !== urlPrefix) {
            localStorage.setItem('app.labelling.url_prefix', urlPrefix);
        }

        return (
            <div className="labelling">
                <div className="container mt-4 text-start">
                    <h2>Génération de balisage</h2>
                    <p>
                        Veuillez insérer les codes EAN des produits Alltricks pour générer les balisages produit.
                        Chaque EAN doit être séparé par un espace, une virgule ou un retour à la ligne.
                    </p>
                    <div>
                        <label htmlFor="codeCollectionInput" className="form-label">Saisie des codes</label>
                        <textarea
                            name="codeCollectionInput"
                            className={`form-control ${codeCollection.length > codeCollectionSliced.length ? 'is-invalid' : 'is-valid'}`}
                            aria-label="Saisie des codes"
                            value={userInput}
                            onChange={event => this.setState({ userInput: event.target.value })}
                        ></textarea>
                        <div className="invalid-feedback">
                            🚨 Vous avez saisie plus de 20 codes, seulement les 20 premiers seront pris en compte.
                        </div>
                        <div className="valid-feedback">
                            👏 Vous avez saisie moins de 20 codes, veuillez restez dans la limite.
                        </div>
                    </div>

                    <div className="form-text cursor-pointer" onClick={() => this.setState({ optAdv: !optAdv })}>
                        Options avancées
                        {optAdv && <i className="bi bi-caret-up"></i>}
                        {!optAdv && <i className="bi bi-caret-down"></i>}
                    </div>
                    {optAdv &&
                        <div className="card mt-2">
                            <div className="card-body">
                                <label htmlFor="urlPrefix" className="card-title">
                                    Ajouter un prefix aux URL
                                </label>
                                <div className="row">
                                    <div className="col-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Prefix URL"
                                            aria-label="urlPrefix"
                                            name="urlPrefix"
                                            value={urlPrefix || ''}
                                            onChange={event => this.setState({ urlPrefix: event.target.value })}
                                        />
                                    </div>
                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="form-text">Si le prefix est invalide, il ne sera pas prix en compte.</div>
                            </div>
                        </div>
                    }


                    <table className="mt-4 table table-light table-bordered">
                        <thead className={codeCollectionSliced.length === 0 ? "d-none" : ""}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Ref</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Prix réduit</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {codeCollectionSliced.map((code, index) => (
                                <tr key={index} className={_trClassname(code)}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                        <NavLinkDisablable
                                            to={`/products/${code}`}
                                            ariaLabel="Fiche produit"
                                            disabled={!_isValid(code)}
                                        >
                                            {code}
                                        </NavLinkDisablable>
                                    </td>
                                    <td>{mementoHas(code) && mementoGet(code).data ? mementoGet(code).data.name : null}</td>
                                    <td>{mementoHas(code) && mementoGet(code).data ? mementoGet(code).data.price : null}</td>
                                    <td>{mementoHas(code) && mementoGet(code).data ? mementoGet(code).data.discountPrice : null}</td>
                                    <td>
                                        <div className="d-flex gap-2 justify-content-center">
                                            <button className="btn btn-outline-primary d-none d-md-block" disabled={!_isValid(code)} onClick={() => this.fetch(code)}>
                                                <i className="bi bi-clipboard-check"></i>
                                            </button>

                                            <NavLinkDisablable
                                                to={`/products/${code}/edit`}
                                                className="btn btn-outline-primary"
                                                ariaLabel="Edition produit"
                                                disabled={!_isValid(code)}
                                            >
                                                <i className="bi bi-pencil"></i>
                                            </NavLinkDisablable>

                                            <LoadingButton disabled={!_isValid(code)} onClick={() => this.download([code])} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className={codeCollectionSliced.length === 0 ? "d-none" : ""}>
                            <tr>
                                <td colSpan="7">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className='btn btn-outline-primary' onClick={() => this.fetchAll(codeCollectionSliced)}>
                                            <i className={`bi bi-clipboard-check`}></i>&nbsp;
                                            Vérification des refs
                                        </button>
                                        <LoadingButton onClick={() => this.download(codeCollectionSliced)} >
                                            Télécharger tous les balisages
                                        </LoadingButton>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }
}

export default Labelling;
