const _stateKey = process.env.REACT_APP_MEMENTO_KEY || 'app.memento';

const _getState = () => {
    return JSON.parse(localStorage.getItem(_stateKey)) || {};
}

const _setState = (state) => {
    return localStorage.setItem(_stateKey, JSON.stringify(state));
}

export const set = (code, state) => {
    const _state = _getState();
    _state[code] = state;
    _setState(_state);
}

export const get = (code) => {
    const state = _getState();
    return state[code] || null;
}

export const has = (code) => {
    return !!get(code);
}
