import { Link, useHistory } from "react-router-dom";
import { logout } from "../../services/authentification";

const Dashboard = () => {
    const history = useHistory();

    const handleLogout = () => {
        logout();
        history.push('/');
    }

    return (
        <div className="dashboard container mt-4">
            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Performance magasin</h5>
                            <p className="card-text">Visualiser le dashboard magasin.</p>
                            <Link to="stats" className="btn btn-primary w-100">
                                Voir dashboard
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Scanner produit</h5>
                            <p className="card-text">Scanner de code barre et code QR.</p>
                            <Link to="scanner" className="btn btn-primary w-100">
                                Scanner produit
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Balisage de masse</h5>
                            <p className="card-text">L'interface de balisage de masse permet la saisie de code multiple, l'édition et la génération des balisage.</p>
                            <Link to="labelling" className="btn btn-primary w-100">
                                Balisage de masse
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Création de balisage</h5>
                            <p className="card-text">La création de balisage vous permez de générer des étiquettes produit via un formulaire.</p>
                            <Link to="/create-product" className="btn btn-primary w-100">
                                Créer un balisage
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <button className="btn btn-danger w-100" aria-label="Déconnexion" onClick={handleLogout}>
                        Déconnexion
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
