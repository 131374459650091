
export const unflatten = (data) => {
    if (Object(data) !== data || Array.isArray(data)) {
        return data;
    }

    // eslint-disable-next-line
    let regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
        resultholder = {};

    for (let p in data) {
        let cur = resultholder,
            prop = "",
            m;

        // eslint-disable-next-line
        while (m = regex.exec(p)) {
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
        }

        cur[prop] = data[p];
    }

    return resultholder[""] || resultholder;
};

export const flatten = (data) => {
    let result = {};

    function recurse (cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++) {
                recurse(cur[i], prop + "[" + i + "]");
            }
            if (l === 0) {
                result[prop] = [];
            }
        } else {
            let isEmpty = true;
            for (let p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }

    recurse(data, "");

    return result;
}
