import { useParams } from "react-router-dom";

const Product = () => {
    const { code } = useParams();
    return (
        <iframe
            title="Alltricks"
            src={`https://www.alltricks.fr/Acheter/${code}`}>
        </iframe>
    )
}

export default Product;
