import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getProductInformation } from '../../clients/toolbox'
import { set as mementoSet, get as mementoGet } from '../../services/memento'
import productSchema from '../../schemas/productSchema'
import ProductFormInput from "../../components/ProductFormInput/ProductFormInput";
import toast from 'react-hot-toast';
import { flatten, unflatten } from "../../helpers/object";

const ProductEdit = () => {
    const { code } = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getProductInformation(code)
            .then(product => {
                if (null === product) {
                    throw new Error('Produit introuvable. Désolé.');
                }

                setProduct(product);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [code, setProduct, setError])

    const handleChange = ({ target }) => {
        const { id, type, value } = target;

        const flattened = flatten(product);
        flattened[id] = type === 'number' ? parseFloat(value) : value;

        setProduct(unflatten(flattened));
    }

    const persist = () => {
        const mementoState = mementoGet(code);
        mementoSet(code, { ...mementoState, data: product });

        toast.success('Sauvegarde réussie!');
    }

    const restore = () => {
        const mementoState = mementoGet(code);
        const { _data } = mementoState;

        setProduct(_data);
        mementoSet(code, { ...mementoState, data: _data });

        toast.success('Restauration réussie!');
    }

    const redirect = () => {
        history.push('/labelling');
    }

    return (
        <div className="container">
            <h1 className="mt-3">Edition produit</h1>
            {loading &&
                <div>loading...</div>
            }
            {!loading && error &&
                <div>{error.message}</div>
            }
            {!loading && product &&
                <div>
                    {Object.keys(productSchema).map(key => (
                        <ProductFormInput
                            key={key}
                            name={key}
                            schema={productSchema[key]}
                            value={product[key]}
                            onChange={handleChange}
                        ></ProductFormInput>
                    ))}
                    <div className="d-flex justify-content-around mb-3">
                        <button type="submit" className="btn btn-light" onClick={redirect}>
                            <i className="bi bi-arrow-left-short" />&nbsp;
                            Revenir
                        </button>
                        <button type="submit" className="btn btn-warning" onClick={restore}>
                            <i className="bi bi-clock-history" />&nbsp;
                            Restaurer
                        </button>
                        <button type="submit" className="btn btn-primary" onClick={persist}>
                            <i className="bi bi-save" />&nbsp;
                            Sauvegarder
                        </button>
                        <button type="submit" className="btn btn-primary" onClick={() => { persist(); redirect(); }}>
                            <i className="bi bi-save" />&nbsp;
                            Sauvegarder et revenir
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductEdit;
