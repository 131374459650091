import './CartIdentification.scss';
import LoadingBar from "../../components/LoadingBar/LoadingBar";

const CartIdentification = () => {
    return (
        <div className="cart-identification">
          <div className="container">
            <div className="cart-identification__loader">
              <LoadingBar step="2" max="4" />
            </div>
            <div className="cart-identification__view">
              <h1>Identification</h1>

              <div className="cart-identification__description">
                L'identification se fait par adresse email, cette étape est <b>obligatoire</b>.
                <br />
                <br />
                Si vous n'avez pas de compte, saisissez votre adresse email et un compte vous sera créé.
              </div>

              <input type="text" className="form-control" placeholder="Email" aria-label="Email" name="email" />

              <div className="cart-identification__footer">
                <button className="btn btn-dark btn-rounded">
                  <i className="bi bi-person-fill" />&nbsp;
                  Identification
                </button>
              </div>
            </div>
          </div>
        </div>
    )
}

export default CartIdentification;
