import { Component } from "react";
import { Link } from "react-router-dom";
import './Homepage.scss';

class Homepage extends Component {
    render() {
        return (
            <div className="home container d-grid gap-4 mt-4">
                <h3 className="home__title">
                    Bienvenue chez Alltricks
                </h3>
                <div className="home__caption">
                    Scanne un code produit ou une etiquette produit pour en savoir plus
                </div>
                <Link className="home__cta" to="scanner">
                    <i className="bi bi-upc-scan"></i>
                </Link>
            </div>
        )
    }
}

export default Homepage;
