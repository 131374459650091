import { useState } from 'react';
import './ContentDrawer.scss'

const ContentDrawer = ({ name, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="content-drawer">
      <div className="row" onClick={() => setOpen(!open)}>
        <div className="col-9">
          {name}
        </div>
        <div className="col-3 text-end">
          {open && <i className="bi bi-chevron-up" />}
          {!open && <i className="bi bi-chevron-down" />}
        </div>
      </div>
      {open &&
        <article>
          {children}
        </article>
      }
    </div>
  )
}

export default ContentDrawer;
