const productSchema = {
    "brand": {
        title: "Nom de la marque",
        type: "text",
    },
    "brandLogo": {
        title: "Logo de la marque (lien vers image)",
        type: "url",
    },
    "name": {
        title: "Nom du produit",
        type: "text",
    },
    "image": {
        title: "Image du produit (lien vers image)",
        type: "url",
    },
    "category": {
        title: "Type de produit",
        type: "enum",
        options: [
            'Vélos complets',
            'Running & Nutrition',
            'Accessoires & Autres',
        ]
    },
    "url": {
        title: "Lien vers la page produit (pour le QR code)",
        type: "text",
    },
    "price": {
        title: "Prix d'origine",
        type: "number",
        min: 0,
    },
    "discountPrice":{
        title: "Prix réduit (soldes, etc.)",
        type: "number",
        min: 0,
    },
    "attributes": {
        title: "Attributs produit (poids, taille, etc.)",
        type: "array",
        of: "text"
    }
}

export default productSchema;
