import './ProductView.scss';
import ContentDrawer from "../../components/ContentDrawer/ContentDrawer";

const mockProduct = {
    "sku": "B21SUSE52",
    "brand": "Kona",
    "brandLogo": "https://media.alltricks.com/brand/normal/5a26b2cc25d99.jpg?1616071397&frz-v=261",
    "image": "https://media.alltricks.com/medium/1827546602d9522726811.53203451.jpg",
    "name": "Kona Sutra AL SE",
    "category": "Vélos complets",
    "price": 1099,
    "discountPrice": 899
}

const ProductView = () => {
    const { image, name, sku, discountPrice, price } = mockProduct;

    return (
        <div className="product-view">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="product-view__image">
                            <img className="w-100" src={image} alt={name} />
                        </div>
                        <div className="row">
                            <h1 className="col product-view__name">
                                {name}
                            </h1>
                            <div className="col product-view__price">
                                <div className="row">
                                    <div className="col primary">
                                        { discountPrice ?? price } €
                                    </div>
                                    {discountPrice !== null &&
                                    <div className="col secondary">{ price } €</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="product-view__variant">
                            Taille: L
                        </div>

                        <div className="product-view__attributes">
                            <table>
                                <tbody>
                                <tr>
                                    <td>Stock mag: 5</td>
                                    <td>Sage: 1657568</td>
                                </tr>
                                <tr>
                                    <td>EAN: {sku}</td>
                                    <td>FNR: {sku}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <ContentDrawer name="Description">
                            Cape est une version moderne du poncho de pluie classique, modifiée pour les cyclistes et inspirée pour un mode de vie actif. Fabriquée dans le tissu imperméable de Rains, Cape est dotée d'une silhouette ample et ondulante et d'une demi-patte avec fermeture à boutons-pression. Grâce aux boutons-pression, il est possible de créer des manches optionnelles pour une tenue plus ajustée. Conçues spécialement pour les cyclistes, des passe-pouces ont été ajoutés aux poignets afin d'assurer une couverture complète. Cape est pourvue de doubles poches passepoilées à rabat et d'une capuche avec cordon de serrage et bonnet intégré, caractéristiques de Rains.
                            Détails:
                            64 % polyester, 36 % polyuréthane
                            Imperméabilité du tissu : 4 000 mm
                            Coutures soudées par ultrasons
                            Coupe ample et confortable
                            Partie antérieure pouvant être légèrement prolongée sur le devant lors des déplacements à vélo
                            Demi-patte avec fermeture à boutons-pression
                            Capuche avec cordon de serrage et bonnet intégré
                            Passes-pouces
                        </ContentDrawer>

                        <div className="product-view__cta">
                            <div className="row">
                                <div className="col-3">
                                    <button className="btn btn-outline-secondary btn-rounded">
                                        <i className="bi bi-trash" />
                                    </button>
                                </div>
                                <div className="col-9">
                                    <button className="btn btn-dark btn-rounded w-100">
                                        Confirmer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductView;
