import './CartTenderItem.scss'

const CartTenderItem = ({ name, icon = 'shop', connected = '' }) => {
  return (
    <div className="cart-tender-item">
      <div className="row">
        <div className="col-2 d-flex justify-content-center">
          <i className={`bi bi-${icon}`} />
        </div>
        <div className="col">
          {name}
        </div>
        <div className="cart-tender-item__status col-4">
          {connected === 'on' &&
            <span className="cart-tender-item__status--on">
              <b>&#8226;</b> Connecté
            </span>
          }
          {connected === 'off' &&
            <span className="cart-tender-item__status--off">
              <b>&#8226;</b> Déconnecté
            </span>
          }
        </div>
      </div>
    </div>
  )
}

export default CartTenderItem;
