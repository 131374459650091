import { useState } from "react";
import { useHistory } from "react-router-dom";
import productSchema from '../../schemas/productSchema'
import ProductFormInput from "../../components/ProductFormInput/ProductFormInput";
import toast from 'react-hot-toast';
import { flatten, unflatten } from "../../helpers/object";
import { getProductsLabel } from "../../clients/toolbox";

const ProductCreate = () => {
    const history = useHistory();
    const [product, setProduct] = useState({ currency: '€', category: "Vélos complets", attributes: [] });
    const [loading, setLoading] = useState(false);

    const handleChange = ({ target }) => {
        const { id, type, value } = target;

        const flattened = flatten(product);
        flattened[id] = type === 'number' ? parseFloat(value) : value;

        setProduct(unflatten(flattened));
    }

    const addAttribute = () => {
        const attributes = [ ...product.attributes, { name: '', value: ''} ]
        const target = { id: 'attributes', type: 'string', value: attributes};
        handleChange({ target });
    }

    const download = () => {
        if (loading === true) {
            return;
        }

        setLoading(true);
        const _tId = toast.loading('Téléchargement en cours...');

        getProductsLabel([product])
            .then(() => {
                toast.dismiss(_tId);
                setLoading(false);
                toast.success('Téléchargement réussi!');
            });
    }

    const redirect = () => {
        history.push('/dashboard');
    }

    return (
        <div className="container">
            <h1 className="mt-3">Création balisage produit</h1>
            <div>
                {Object.keys(productSchema).map(key => (
                    <ProductFormInput
                        key={key}
                        name={key}
                        schema={productSchema[key]}
                        value={product[key]}
                        onChange={handleChange}
                    >
                        {key === 'attributes' && <button type="submit" className="btn btn-primary" onClick={addAttribute}>
                            <i className="bi bi-plus" />&nbsp;
                            Ajouter attribut
                        </button>}
                    </ProductFormInput>
                ))}

                <div className="d-flex justify-content-around mb-3">
                    <button type="submit" className="btn btn-light" onClick={redirect}>
                        <i className="bi bi-arrow-left-short" />&nbsp;
                        Revenir
                    </button>
                    <button type="submit" className="btn btn-primary" onClick={download}>
                        <i className="bi bi-download" />&nbsp;
                        Télécharger le balisage
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProductCreate;
