import './CartProduct.scss';

const CartProduct = ({ product }) => {
    const { sku, image, name, price, discountPrice } = product;
    const outOfStock = sku === "010-12883-00";
    return (
        <div className="cart-product">
            <div className="cart-product__top row">
                <div className="col-4 d-flex justify-content-center align-items-center">
                    <img className="mw-100 mh-100" src={image} alt={name} />
                </div>
                <div className="col-8">
                    <div className="cart-product__name">
                        { name }
                    </div>
                    <div className="cart-product__attributes row">
                        <div className="col">Taille: L</div>
                        <div className="col">Quantité: 1</div>
                    </div>
                    <div className="cart-product__prices row">
                        <div className="col primary">{ discountPrice ?? price } €</div>
                        {discountPrice !== null &&
                            <div className="col secondary">{ price } €</div>
                        }
                    </div>
                </div>
            </div>
            {outOfStock &&
                <div className="cart-product__bottom row">
                    <div className="col">
                        <i className="bi bi-exclamation-triangle"></i>
                        Stock négatif sur cet article; Inventaire nécessaire
                    </div>
                </div>
            }
        </div>
    )
}

export default CartProduct;
